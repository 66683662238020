@import './utils/variables';
@import './utils/mixins';
.header {
  max-width: 100%;
  background-color: #4e4d4d !important;
  padding: 0 80px;
  // overflow: hidden;
  @include respond-before-phone {
    padding: 0 20px;
  }
  @include respond-phone {
    padding: 0 10px;
  }
  @include respond-tablet {
    padding: 0 40px;
  }
  &__body {
    // overflow: hidden;
    width: 100%;
    // background-color: #f00;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;

    &-left {
      display: flex;
      align-items: center;
      &-menu {
        display: none;
        @include respond-tablet {
          display: block;
        }
        @include respond-before-phone {
          display: block;
        }
      }
      svg {
        width: 25px;
        height: 25px;
        fill: #fff;
        margin-right: 5px;
        cursor: pointer;
      }
      &-logo {
        svg {
          width: auto;
          height: 40px;
          margin-left: 15px;
          fill: unset;
        }
      }
    }
  }

  &__options {
    // background-color: #f0f !important;
    height: 60px !important;
    // flex-grow: 1;
    .search {
      height: 30px;
      display: flex;
      justify-content: center;
      margin-right: 5px;
      svg {
        fill: #fff;
        width: 25px;
        height: 25px;
        font-weight: 700;
        stroke: #fff;
        stroke-width: 1.5px;
      }
    }
    .header__register {
      height: 35px;
      background-color: $header-background;
      border: 0.5px solid #fff;
      color: #fff;
      margin-left: 10px;
      padding: 0 20px;
      text-transform: unset;
      border-radius: 2px;
      font-size: $header-font-size;
    }
    &-auth {
      // background-color: #00f;
      &--web {
        // @include respond-tablet {
        //     display: none;
        // }
        @include respond-before-phone {
          display: none;
        }
        font-size: $header-font-size;
        color: #fff;
        display: flex;
        text-align: center;
        .button-group {
          position: relative;
          z-index: 9999999999;
          .header__button {
            height: 35px;
            background-color: transparent;
            border: none;
            color: #fff;
            font-size: $header-font-size;
            // &--auth{
            //     background-color: yellow !important;
            //     // border: 1px solid yellow;
            //     padding: 0;
            //     font-size: $header-font-size;
            //     text-transform: unset;
            // }
            &-open {
              position: absolute;
              width: 100%;
              background-color: transparent;

              &-on {
                width: 100%;
                span {
                  display: none;
                  width: 35px;
                  height: 35px;
                  background-color: #0ff;
                  z-index: 99999;
                  margin-left: 100px !important;
                  // display: block;
                  svg {
                    fill: #fafd00 !important;
                  }
                }
              }
              // width: 100%;
            }
          }
        }

        & .balance,
        & .deposit {
          display: grid;
          grid-template-columns: repeat(2, max-content);
          place-items: center;
          span {
            cursor: pointer;
          }

          &::after {
            content: '';
            width: 1px;
            height: 14px;
            background: #fff;
            display: inline-block;
            vertical-align: middle;
            margin: 0 16px;
          }
        }
        & .balance {
          .amount {
            color: #fafd00;
            font-weight: 700;
            .visible {
              margin-left: 5px;
              position: relative;
              &.hide-balance {
                &::before {
                  content: '\2758';
                  font-size: 29px;
                  color: #fff;
                  font-style: normal;
                  -webkit-font-smoothing: antialiased;
                  -moz-osx-font-smoothing: grayscale;
                  position: absolute;
                  top: -12px;
                  right: 5px;
                  transform: rotate(45deg);
                  font-weight: 100;
                }
              }
              svg {
                width: 18px;
                height: 18px;
                fill: #fff;
              }
            }
          }
        }
      }
      &--mobile {
        display: flex;
        align-items: center;
        display: none;
        @include respond-before-phone {
          display: flex;
        }
        @include respond-phone {
          display: flex;
        }
        & > * {
          border-radius: 4px;
        }
        .user {
          background-color: $header-background;
          padding: 5px;
          height: 30px;
          width: 30px;
          box-sizing: border-box;
          border: 0.5px solid white;
          display: flex;
          justify-content: center;
          cursor: pointer;
          position: relative;
          z-index: 222;
          // overflow: hidden;
          .header__button-open {
            &-on {
              position: absolute;
              z-index: 444;
              top: -5px;
              left: -13px;
              background-color: transparent;
              border: none !important;
              svg {
                fill: transparent !important;
              }
            }
            .header__dropdown {
              //  background-color: #0ff;
              position: absolute;
              top: 20px;
              left: -110px;
              z-index: 111;
            }
          }
          svg {
            width: 18px;
            height: 18px;
            fill: #fff;
            display: block;
            position: absolute;
            z-index: 225;
          }
        }
        &-button {
          background-color: #fff !important;
          margin-right: 5px;
          color: $input-background;
        }
      }
    }
    &-web {
      display: flex;
      @media (max-width: 900px) {
        display: none;
      }
      form {
        display: flex;
        .form-content {
          display: flex;
          flex-direction: column;
          .error {
            color: #4e4d4d;
            font-size: 13px;
          }
        }
        input {
          height: 35px;
          background: $input-background;
          border: none;
          font-size: 11.8px;
          color: #fff !important;
          &:focus {
            background: $input-background;
          }
          &::placeholder {
            font-size: 11.8px;
          }
          &:first-child {
            margin-right: 5px;
          }
        }
      }
      .header__button {
        height: 35px;
        background-color: #393939;
        border: none;
        color: #fff;
        opacity: 0.7;
        font-size: $header-font-size;
        // &--auth{
        //     background-color: yellow !important;
        //     // border: 1px solid yellow;
        //     padding: 0;
        //     font-size: $header-font-size;
        //     text-transform: unset;
        // }
        &.active {
          opacity: 1;
        }
      }

      .facebook {
        border: 0.5px solid #fff;
        height: 35px;
        width: 35px;
        margin-left: 10px;
        display: grid;
        place-items: center;
        padding: 3px;
        box-sizing: border-box;
        border-radius: 2px;
        cursor: pointer;
        svg {
          width: 18px;
          height: 18px;
          fill: #fff;
        }
      }
    }
    &-mobile {
      display: none;
      @media (max-width: 900px) {
        display: flex;
      }
    }
  }
}

.header__dropdown {
  min-width: 100% !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  z-index: 999999;
  border-radius: 1.5px;
  background-color: #fafafa;
  ul {
    font-size: $header-font-size;
    padding: 0 15px;
    li {
      text-align: left;
      &:not(:last-child) {
        margin: 3px 0;
        cursor: pointer;
      }
      &.balance {
        font-weight: 600;
      }
      &.logout {
        color: $header-background;
        margin-bottom: 3px;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

.hhad{
  font-family: "Kanit",sans-serif !important;
  font-size: 2rem;
}

.hhs {
  font-family: "Kanit",sans-serif !important;
  font-size: 1rem;
}