@import './utils/variables';
.cashout {
  background-color: #50545e;
  min-height: 182px;
  color: #888;
  font-size: 14px;

  &__matches {
    background-color: #fff;
    // min-height: 250px;
    padding: 5px;
    .view-all {
      cursor: pointer;
    }
    .cashout__info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      .detail {
        color: $font-color;
        font-weight: 600;
      }

      &-stake {
      }
      &-winning {
      }
    }
  }
  &__match {
    display: flex;
    align-items: stretch;

    &-date {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 35px;

      &--day {
        font-weight: 700;
        font-size: 19px;
      }
    }
    &-selections {
      color: $font-color;
      margin-left: 10px;
      .market {
        font-weight: 600;
      }
    }
  }

  &__state {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #9ca0ab;
    color: #fff;
    font-weight: 600;
    padding: 2px 5px;
    box-sizing: border-box;
    .status {
      display: flex;
      align-items: center;
      svg {
        fill: currentColor;
        width: 16.5px;
        height: 16.5px;
        margin-left: 5px;
        transform: rotate(270deg);
        transition: 0.1s all;
      }
      &:hover {
        svg {
          transform: translateX(3px) rotate(270deg);
        }
      }
    }
    span {
    }
  }
  .no-bets {
    width: 100%;
    height: 182px !important;
    display: block;
    display: grid;
    place-items: center;
    span {
      display: block;
    }
  }
}
