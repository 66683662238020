$color-primary: #14805e;
$bg-main: #474747;
$bg-leagues: #333;
$border-bottom: #404040;
$bg-secondary: #585858;
$input-background: #b60606;
$font-color: #353a45;
$border-color: rgb(64, 64, 64);
$odd-color: #0d9737;
$button-hover: #32ce62;
$header-background: #e41827;
$header-font-size: 13px;
$modal-bg: rgba(
  $color: #000000,
  $alpha: 0.6
);
