@import './utils/variables';
@import './utils/mixins';
.leagues {
  background-color: #fff !important;
  // min-height: 100vh;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0);
  border: 1px solid #b4b4b4;
  // @include respond-tablet {
  //     display: none;
  // }
  .list-group {
    // margin: 0;
    // padding: 0;
    margin-bottom: 0 !important;
    border-radius: 0;
    &-item {
      // list-style: none;
      // padding: 0;
      margin-top: 0 !important;
      position: relative;
      color: $font-color;
      background-color: #fff;
      text-align: left;
      padding: 7px 10px !important;
      font-size: 15.2px;
      border-color: $border-color;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .league-star {
        fill: #fff;
        stroke: $font-color;
        stroke-width: 20px;
        stroke-linejoin: miter-clip;
        width: 15px;
        height: 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          display: block;
          stroke-width: 0;
          width: 100%;
          height: 100%;
          fill: #ccc;
        }
        &.selected {
          svg {
            fill: #ffdf1b !important;
          }
        }
      }

      .league-link {
        color: $font-color;

        &:hover {
          text-decoration: none;
          font-weight: 400;
        }
      }
    }
  }
}

.loaders {
  // background-color: #0f0;
  padding: 10px;
  span {
    text-align: center;
  }
}

.table-loader {
  span {
    width: 100% !important;
  }
}

.react-loading-skeleton {
  border-radius: 0 !important;
}
